import React from 'react';
import { Link } from 'react-router-dom';
import {Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    btn: {
        background: "#fff",
        color: '#000',
        padding: "20px",
        fontSize: '16px',
        fontWeight: '500',
        textDecoration: 'none'
    }
}));


function NotFound() {
    const classes = useStyles();

    return (
        <Grid>
            <Box py={10} bgcolor="secondary.main" color="white">
                <Container justify="center" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="h1" mb={5}>404 - Oops! You seem to be lost.</Typography>
                            <Link to='/' className={classes.btn}>Go Back Home</Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>  
        </Grid>
    )
}

export default NotFound;