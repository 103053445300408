import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import logo from './logo200.png';

const useStyles = makeStyles((theme) => ({
	header: {
        height: '5rem',
        display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
        backgroundColor: '#fff',
        padding: '0 10%',

        "& ul": {
            listStyle: 'none',
            margin: '0',
            padding:' 0',
            display: 'flex',
            alignItems: 'baseline',
        },

        "& li": {
            marginLeft: '3rem',
        },
        "& a": {
            textDecoration: 'none',
            fontSize: '1rem',
            color: '#0B0B15',
        },
        "& a:hover": {
            color: '#1e3faf',
        },

        "& a:active": {
            color: '#1e3faf',
        },
        "& a.active": {
            color: '#1e3faf',
        }
	},
    logo: {
        fontSize: '1rem',
        color: 'white',
        fontWeight: 'bold',
    },
    badge: {
        backgroundColor: '#cc2062',
        color: 'white',
        borderRadius: '12px',
        padding: '0 1rem',
        marginLeft: '0.5rem',
    },
    logoImg: {
        width: '150px',
    }
	
}));

function MainNavigation() {
    const classes = useStyles();

    return (
        <header className={classes.header}>
            <div className={classes.logo}>
                <Link to='/'>
                    <img src={logo} className={classes.logoImg} alt="logo" />
                </Link>
            </div>
            <nav>
                <ul>
                    <li>
                        <Link to='/'>Jobs</Link>
                    </li>
                    {/* <li>
                       <Link to='/uploadFile'>Export File to DB</Link>
                    </li> */}
                    <li>
                        <Link to='/blog'>News & Articles</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default MainNavigation;