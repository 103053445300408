import React, { useState, useEffect} from "react";
import {Grid, Box, Container, Pagination, Typography, TextField,  Autocomplete } from '@mui/material';
import JobCard from "./JobCard";
import { makeStyles } from '@mui/styles';
import { State }  from 'country-state-city';

const useStyles = makeStyles({
    wrapper: {
        backgroundColor: "#fff",
        display: "flex",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        "& > *": {
            flex: 1,
            height: "45px",
            margin: "8px",
        },
    },
    pagination: {
       padding: '30px 0px'
    }
});

const JobsList = () => {
    const JSONFile = process.env.REACT_APP_JSON_FILE;
    const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [documents, setDocuments] = useState([]);
    const [error, setError] = useState(null);
    // const [selectedState, setSelectedState] = useState(null);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [pageSize] = useState(15); // Adjust as needed
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Initialize with 1 page

    useEffect(()=>{
        try {	
            fetch(JSONFile)
            .then((res) => {
                return res.json();
            })
            .then((data) => {                
                const loadedJobs = Object.keys(data).map((key) => ({
                    id: key,
                    ...data[key],
                }));
                setLoading(false);
                setDocuments(loadedJobs);
                setFilteredDocuments(loadedJobs);
                setTotalPages(Math.ceil(loadedJobs.length / pageSize)); // Calculate total pages
            });
            
		} catch (error) {
			console.error('Error fetching data:', error);
            setError(error);
		}
    }, [JSONFile, pageSize]);

    const handleInputChange = (event, newValue) => {
        const searchTerm = newValue?.isoCode || '';
        // setSelectedState(newValue);
        const filteredItems = documents.filter(job => 
            job.job_state && job.job_state.toLowerCase().includes(searchTerm.toLowerCase()) 
        );
        setFilteredDocuments(filteredItems);
        setTotalPages(Math.ceil(filteredItems.length / pageSize)); // Calculate total pages
    };

    const handleChange = (event, page) => {
        setCurrentPage(page);
    };

    // Calculate indexes for pagination
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedJobs = filteredDocuments.slice(startIndex, endIndex);

    return (        
        <Box>
            <Box mt={-13} color="white">
                <Container justify="center" maxWidth="lg">
                    <Grid xs={12}>
                        <Autocomplete
                            options={State.getStatesOfCountry("US")} 
                            getOptionLabel={option => option.name}
                            onChange={handleInputChange}
                            renderInput={params => <TextField {...params} label="State"  />}
                            sx={{
                                width: 600,
                                bgcolor: 'background.paper',
                                color: (theme) =>
                                theme.palette.getContrastText(theme.palette.background.paper),
                            }}
                        />
                    </Grid>
                </Container>
            </Box> 

            <Box component="section" sx={{ p: 2, marginTop: '80px'  }}>
                <Container justify="center" maxWidth="lg">
                    <Grid item xs={12}>
                        <Typography variant="h4">Latest Jobs</Typography>
                    </Grid>
                </Container>
            </Box>
           
            <Box component="section" sx={{ marginBottom: '60px' }}>
                <Container justify="center">
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {loading && <p>Loading...</p>}
                            {!loading && !error && paginatedJobs.length === 0 ? (
                                    <p>No Jobs found</p>
                                ) : (
                                    paginatedJobs.map((job) => <JobCard key={job.id} {...job} />)
                                )
                            }
                            <div className={classes.pagination}>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChange}
                                    color="primary"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default JobsList;


