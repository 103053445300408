import React from "react";
import JobsList from "../components/Job/JobsList.js";
import {Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import coffeeImage from '../components/Layout/coffeeshop.jpg';
import Post1 from '../components/Blogs/Post1.js';
import Post2 from '../components/Blogs/Post2.js';
import Post3 from '../components/Blogs/Post3.js';
import Post4 from '../components/Blogs/Post4.js';
import Post5 from '../components/Blogs/Post5.js';


const useStyles = makeStyles({

    header: {
		backgroundImage: `url(${coffeeImage})`,
		backgroundSize: 'cover',
        backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		height: '200px',
		display: 'flex',
        flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
        color: 'white'
	},
    wrapper: {
        backgroundColor: "#fff",
        display: "flex",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        "& > *": {
            flex: 1,
            height: "45px",
            margin: "8px",
        },
    }
});


const HomePage = (props)  => {
    const classes = useStyles();
    const posts = [
        Post1(),
        Post2(),
        Post3(),
        Post4(),
        Post5(),
    ];

    return (
        <Grid>
            <Box py={10} bgcolor="secondary.main" color="white" className={classes.header}>
                <Container justify="center" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="h1">Find your next Barista Job</Typography>
                        </Grid>

                    </Grid>
                </Container>
            </Box>  

            <Box>
                <Container justify="center">
                    <Grid container spacing={4}>
                        <Grid item xs={8}>
                            <JobsList />
                        </Grid>

                        <Grid item xs={4} sx={{ p: 2, marginTop: '80px' }}>
                            <Grid item xs={12}>
                                <Typography variant="h4">News and Articles</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 4 }}>
                                    {posts.map((post, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {/* <Link to={`/blog/${index + 1}`}  underline="none">{post.title}</Link> */}

                                                <Link to={`/blog/${post.slug}`}  underline="none">{post.title}</Link>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {post.excerpt}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Grid>
    );
}

export default HomePage;