import { Route, Routes } from 'react-router-dom';
import {ThemeProvider} from '@mui/material';
import theme from "./theme/theme";
import Layout from './components/Layout/Layout';
import MainNavigation from './components/Layout/MainNavigation';
import HomePage from './pages/HomePage';
import MainBlogPage from './pages/MainBlogPage';
import SingleBlogPage from './pages/BlogPost';
import ReactGA from "react-ga4";
import Footer from './components/Layout/Footer';
import NotFound from './pages/NotFound';

const GA4ID = process.env.REACT_APP_ANALYTICS;

// function NoMatch() {
// 	return (
// 		<div style={{ padding: 20 }}>
// 			<h1>404- Page not Found</h1>
// 		</div>
// 	);
// }

function App() {
	ReactGA.initialize(GA4ID);
	ReactGA.send({ hitType: "pageview", page: window.Location.pathname, });
	
	return (
		<div className="App">
			<MainNavigation />
			<ThemeProvider theme={theme}>
				<Layout>
				<Routes>
						<Route path="/" 
							element={<HomePage />}/>
						{/* <Route path="/jobs" 
							element={<HomePage />}/> */}
						{/* <Route path="/jobs"
							element={<AllJobs />} /> */}
						<Route exact path="/blog"
							element={<MainBlogPage />} />	

{/* 
							<Route path="/blog/:slug" element={<MainBlogPage />} />	 */}
						
						<Route path="*" element={<NotFound />} />
						<Route path="/blog/:slug" element={<SingleBlogPage />}  />
					</Routes>
				</Layout>
			</ThemeProvider>
			<Footer/>
		</div>
	);
}

export default App;
