// import Posts from "../components/Posts/Posts";
import React from 'react';
import { Link } from 'react-router-dom';
import {Grid, Box, Container, Typography, Card, CardContent, Button, CardActions} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Post1 from '../components/Blogs/Post1';
import Post2 from '../components/Blogs/Post2';
import Post3 from '../components/Blogs/Post3';
import Post4 from '../components/Blogs/Post4';
import Post5 from '../components/Blogs/Post5';



const useStyles = makeStyles({
    header: {
        backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		height: '100px',
		display: 'flex',
        flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
        color: 'white'
	}
});

const MainBlogPage = ()  => {
    const classes = useStyles();
    const posts = [
        Post1(),
        Post2(),
        Post3(),
        Post4(),
        Post5(),
    ];

    return (
        <Box mb={8}>
            <Box py={10} bgcolor="secondary.main" color="white" className={classes.header}>
                <Container justify="center" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="h1">News and Articles</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box> 

            <Box component="section" sx={{ p: 2, marginTop: '30px'  }}>
                <Container justify="center" maxWidth="lg">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {posts.map((post, index) => (
                            <Grid item xs={4}>
                                <Card key={index}>
                                    {/* <CardMedia
                                    component="img"
                                    height="140"
                                    image="/static/images/cards/contemplative-reptile.jpg"
                                    alt="green iguana"
                                    /> */}
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {/* <Link to={`/blog/${index + 1}`}  underline="none">{post.title}</Link> */}

                                            <Link to={`/blog/${post.slug}`}  underline="none">{post.title}</Link>
                                            {/* <Link to={`/blog/${post.slug}`}>{post.title}</Link> */}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {post.excerpt}
                                        </Typography>
                                    </CardContent>
                                
                                    <CardActions>
                                        <Button size="small" color="primary" component="a" href={`/blog/${post.slug}`}>Read more</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Box> 
    );
}

export default MainBlogPage;

