// SingleBlogPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import {Box, Grid, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Post1 from '../components/Blogs/Post1';
import Post2 from '../components/Blogs/Post2';
import Post3 from '../components/Blogs/Post3';
import Post4 from '../components/Blogs/Post4';
import Post5 from '../components/Blogs/Post5';

const useStyles = makeStyles({
    header: {
		
        backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		height: '100px',
		display: 'flex',
        flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
        color: 'white'
	},
    wrapper: {
        backgroundColor: "#fff",
        display: "flex",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        "& > *": {
            flex: 1,
            height: "45px",
            margin: "8px",
        },
    }
});

const SingleBlogPage = () => {
	const classes = useStyles();
	const { slug } = useParams();
	let post;

	switch(slug) {
		case 'how-to-navigate-the-barista-job-market':
			post = Post1(); // Get the title, excerpt, and content from Post1 component
			break;
		case 'top-10-skills-for-barista':
			post = Post2(); // Get the title, excerpt, and content from Post1 component
			break;
		case 'how-to-write-barista-resume':
			post = Post3(); // Get the title, excerpt, and content from Post1 component
			break;
		case 'barista-job-art':
			post = Post4(); // Get the title, excerpt, and content from Post1 component
			break;
		case 'barista-tools-breville-barista-express':
			post = Post5(); // Get the title, excerpt, and content from Post1 component
			break;
		default:
		post = null;
	}
	
	return (
		<Box mb={5}>
			{post && (
				<Box>
					<Box py={10} bgcolor="secondary.main" color="white" className={classes.header}>
						<Container justify="center" maxWidth="lg">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h1">{post.title}</Typography>
								</Grid>
							</Grid>
						</Container>
					</Box> 

					<Box>
						<Container justify="center" maxWidth="lg">
							<Grid container spacing={2}>
								{post.content} {/* Render content directly */}
							</Grid>
						</Container>
					</Box> 
				</Box> 
					
			)}
		</Box>
	);
};

export default SingleBlogPage;
