import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	footerSection: {
        display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
        backgroundColor: '#262626',
	},
}));

const Footer = (props)  => {
    const classes = useStyles();
    return (
        <Box component="section" sx={{ p: 2}} className={classes.footerSection}>
            <Typography color="primary.contrastText">© 2024 Barista Job Board</Typography>
        </Box>
    );
}

export default Footer;