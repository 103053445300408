import { createTheme } from '@mui/material/styles'
import { blue } from '@mui/material/colors';

const primary = {
	main: '#172554',
	light: '#eff3ff',
	dark: '#eff3ff',
	contrastText: '#fff',
};
  
const secondary = "#1e378a";

export default createTheme({
	typography: {
		fontFamily: "Poppins, sans-serif",
		fontSize: 15,

		h1: {
			fontSize: 62,
			fontFamily: "'Playfair Display', Georgia, serif",
			
		},
		h2: {
			fontSize: 18,
			color: primary.dark,
			fontWeight: 800
		},
		h3: {
			fontSize: 36,
			fontWeight: 600
		},
		h4: {
			fontSize: 32,
			fontWeight: 600,
			textDecoration: 'none'
		},
		h5: {
			fontSize: 22,
			fontWeight: 600
		},
		h6: {
			fontSize: 18,
		},
		subtitle1: {
			fontSize: 22,
			fontWeight: 600
		},
	},
	palette: {
		primary: {
			main: blue[900],
		},
		secondary: {
			main: secondary,
		},
	},
	overrides: {
		MuiDialogActions: {
			root: {
				padding: "8px 24px 16px 24px",
			},
		},
		MuiButton: {
			root: {
				fontWeight: 600,
				textTransform: "none",
				color: secondary,
				padding: "6px 24px",
			},
			outlined: {
				borderRadius: "35px",
				borderColor: secondary,
				padding: "6px 20px",
			},
			contained: {
				borderRadius: "35px",
				borderColor: secondary,
				backgroundColor: primary.dark,
				padding: "6px 20px",
			}
		},
		MuiSelect: {
			filled: {
				padding: "15px 0 15px 15px",
			},
		},
		MuiFilledInput: {
			input: {
				height: "49px",
				padding: "0px 0 0 10px",
			},
		},
		MuiLink: {
			
		}
	},
});